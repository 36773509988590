import {
  Accordion as AccordionWrapper,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { getTranslation } from "@/i18n/utils";

interface AccordionProps {
  items: {
    title: string;
    description: string;
    url?: string;
  }[];
  locale: "it" | "en";
}

const Accordion = ({ items, locale }: AccordionProps) => {
  const t = getTranslation(locale);
  return (
    <AccordionWrapper
      type="single"
      collapsible
      className="flex flex-col w-full mobile:w-full gap-6"
    >
      {items.map((item, index) => (
        <AccordionItem key={`accordion${index}`} value={`accordion${index}`}>
          <AccordionTrigger className="text-start">
            {item.title}
          </AccordionTrigger>
          <AccordionContent>
            {item.description}
            {item.url && (
              <a href={item.url} className="ml-2 font-semibold">
                {t("shared.see_more")}
              </a>
            )}
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionWrapper>
  );
};

export default Accordion;
