import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import BottleCard, {
  type BottleCardProps,
} from "@/components/Cards/BottleCard";
import { APP_URL } from "@/constants";

interface BottleCardCarouselProps {
  items: BottleCardProps[];
  carouselItemStyle: string;
  carouselStyle?: string;
  locale: "it" | "en";
}

const BottleCardCarousel = ({
  items,
  carouselItemStyle,
  carouselStyle,
  locale,
}: BottleCardCarouselProps) => {
  return (
    <Carousel className={carouselStyle}>
      <CarouselContent className="py-16 tablet:py-8 mobile:py-8">
        {items.map((e, index) => (
          <CarouselItem key={index} className={carouselItemStyle}>
            <BottleCard
              name={e.name}
              image={e.image}
              producer={e.producer}
              variation={e.variation}
              tradingPrice={e.tradingPrice}
              link={APP_URL}
              locale={locale}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};

export default BottleCardCarousel;
