interface FlagITProps {
  size?: string;
}

const FlagIT = ({ size }: FlagITProps) => (
  <svg
    width={size || "30"}
    height={size || "24"}
    viewBox="0 0 3 2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="3" height="2" fill="#009246" />
    <rect width="2" height="2" x="1" fill="#fff" />
    <rect width="1" height="2" x="2" fill="#ce2b37" />
  </svg>
);

export default FlagIT;
