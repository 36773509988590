import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { getAltTextFromSrc } from "@/lib/utils";

interface ScrollCarouselProps {
  items: string[];
  carouselItemStyle: string;
}

const ImageCarousel = ({ carouselItemStyle, items }: ScrollCarouselProps) => {
  return (
    <Carousel>
      <CarouselContent>
        {items.map((item, index) => (
          <CarouselItem key={index} className={carouselItemStyle}>
            <img src={item} alt={getAltTextFromSrc(item)} />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};

export default ImageCarousel;
