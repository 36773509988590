import {
  CarouselContent,
  CarouselDots,
  CarouselItem,
  Carousel,
} from "@/components/ui/carousel";
import BlogCard, { type CardProps } from "@/components/Cards/BlogCard";

interface BlogCarouselProps {
  items: CardProps[];
}

const BlogCarousel = ({ items }: BlogCarouselProps) => {
  return (
    <Carousel className="w-[400px]">
      <CarouselContent className="w-[400px]">
        {items.map((item, index) => (
          <CarouselItem key={`blog-card-${index}`} className=" w-fit">
            <BlogCard
              image={item.image}
              link={item.link}
              title={item.title}
              editor={item.editor}
              date={item.date}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselDots />
    </Carousel>
  );
};

export default BlogCarousel;
