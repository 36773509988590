interface ArrowProps {
  size?: string;
}
const ArrowDown = ({ size }: ArrowProps) => {
  return (
    <svg
      width={size || "16"}
      height={size || "10"}
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#111827"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m1 1.243 6.772 7.655a.304.304 0 0 0 .456 0L15 1.243"
      />
    </svg>
  );
};

export default ArrowDown;
